import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit, FiRefreshCw } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { FormHandles } from "@unform/core";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { formatLocaleSP } from "../../utils/formatLocale";

import * as Styled from "./styles";

interface OperatorsFromGetOperators {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

interface OperatorsApiResponse {
  data: OperatorsFromGetOperators[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

const Operators: React.FC = () => {
  const [operators, setOperators] = useState<
    OperatorsFromGetOperators[] | null
  >(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetOperators = useCallback(
    async (size = 8, page = 1) => {
      try {
        setIsLoading(true);
        const { data: operatorsResponse } = await api.get<OperatorsApiResponse>(
          `operators?page[size]=${size}&page[number]=${page}`,
        );

        setCurrentPage(page);
        setTotalPages(operatorsResponse.last_page);
        setOperators(operatorsResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        handleGetOperators(8, 1);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [debounceTimer, handleGetOperators],
  );

  useEffect(() => {
    handleGetOperators();
  }, [handleGetOperators]);

  return (
    <Container>
      <Sidebar />

      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.OperatorsContainer>
          <Styled.Title>
            <div>
              Gerenciar - Operadores{" "}
              <FiRefreshCw size={20} onClick={() => handleGetOperators(8, 1)} />
            </div>
            <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
              <Styled.TopLink to="/gerenciar/novo-operador">
                + Novo operador
              </Styled.TopLink>
              <BorderlessInput
                type="text"
                id="search"
                name="search"
                onChange={debounceSearch}
                placeholder="Pesquisar"
                autoComplete="off"
              />
            </Styled.SearchForm>
          </Styled.Title>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.OperatorsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.OperatorsShimmer>
            ) : (
              <Styled.Ul>
                {operators?.map((operator) => {
                  return (
                    <Styled.Li color="var(--color-primary)" key={operator.id}>
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{operator.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Nome Fantasia:</span>
                        <strong>{operator.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Email:</span>
                        <strong>{operator.email}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Criado em:</span>
                        <strong>
                          {formatLocaleSP(
                            operator.created_at,
                            "dd/MM/yyyy 'às' HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Última atualização:</span>
                        <strong>
                          {formatLocaleSP(
                            operator.updated_at,
                            "dd/MM/yyyy 'às' HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.ActionField>
                        <Styled.Link
                          to={`/operadores/${operator.id}`}
                          isDisabled
                        >
                          <FiEdit size={24} />
                        </Styled.Link>
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetOperators}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )}
          </Styled.ListContainer>
        </Styled.OperatorsContainer>
      </ContainerMain>
    </Container>
  );
};

export default Operators;
