import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiRefreshCw, FiZoomIn, FiEyeOff, FiEye } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { FormHandles } from "@unform/core";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import { formatCurrencyByText } from "../../utils/inputAndTextMasks";
import { formatLocale } from "../../utils/formatLocale";

import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import * as Styled from "./styles";
import BanklyDetailsModal from "../../components/BanklyDetailsModal";

interface TransactionProps {
  color?: string;
  channel: string;
  amount: number;
  operation: string;
  createdAt: string;
  authenticationCode: string;
  identifier: string;
  recipient?: {
    documentNumber?: string;
    account: {
      branch: string;
      number: string;
      ispb: string;
    };
  };
}

interface TransactionsResponseProps {
  nextPage?: string;
  previousPage?: string;
  data: TransactionProps[];
}

const Bankly: React.FC = () => {
  const [transactions, setTransactions] = useState<TransactionProps[] | null>(
    null,
  );
  const [balance, setBalance] = useState(0);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [previousPage, setPreviousPage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isSizesVisible, setIsSizesVisible] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(8);
  const sizesContainerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionProps | null>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const sizes = [8, 20, 30, 50];

  const handleGetBalance = useCallback(async () => {
    try {
      setIsLoadingBalance(true);
      const { data } = await api.get("/bankly/balance");
      setBalance(data.balance.available.amount);
    } catch (err) {
      addToast(err.response.data.message, {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    setIsLoadingBalance(false);
  }, [addToast]);

  const handleGetTransactions = useCallback(
    async (size = 8, type = "initial") => {
      try {
        setIsLoading(true);
        let url;

        switch (type) {
          case "initial":
            url = `bankly/statement?pageSize=${size}`;
            break;
          case "next":
            url = `bankly/statement?pageSize=${size}&nextPage=${nextPage}`;
            break;
          case "previous":
            url = `bankly/statement?pageSize=${size}&nextPage=${previousPage}`;
            break;
          default:
            url = `bankly/statement?pageSize=${size}`;
            break;
        }

        const { data: transactionsResponse } =
          await api.get<TransactionsResponseProps>(url);

        transactionsResponse.data.forEach((transaction) => {
          switch (transaction.operation) {
            case "CASH_OUT":
              transaction.operation = "Saída";
              transaction.color = "#e5e619";
              break;
            case "CASH_IN":
              transaction.operation = "Entrada";
              transaction.color = "#4c4cff";
              break;
            default:
              break;
          }
        });

        if (transactionsResponse.nextPage) {
          setNextPage(transactionsResponse.nextPage);
        } else {
          setNextPage(null);
        }

        if (transactionsResponse.previousPage) {
          setPreviousPage(transactionsResponse.previousPage);
        } else {
          setPreviousPage(null);
        }

        setTransactions(transactionsResponse.data);
        setIsLoading(false);
      } catch (err) {
        addToast(err.response.data.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast, nextPage, previousPage],
  );

  const handleSetBalanceVisibility = useCallback(() => {
    setIsBalanceVisible((state) => !state);
  }, []);

  /* const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        handleGetTransactions();
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [debounceTimer, handleGetTransactions],
  ); */

  const handleSetSizesVisibility = useCallback(() => {
    setIsSizesVisible((state) => !state);
  }, []);

  const handleChooseSize = useCallback(
    (size: number) => {
      setCurrentPageSize(size);
      handleGetTransactions(size, "initial");
    },
    [handleGetTransactions],
  );

  const handleSetIsModalOpen = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, []);

  const handleViewTransactionDetails = useCallback(
    (transaction) => {
      setSelectedTransaction(transaction);
      handleSetIsModalOpen();
    },
    [handleSetIsModalOpen],
  );

  useEffect(() => {
    handleGetTransactions();
    handleGetBalance();
  }, [handleGetTransactions, handleGetBalance]);

  useEffect(() => {
    if (isSizesVisible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!sizesContainerRef.current?.contains(event.target as HTMLElement)) {
          setIsSizesVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return undefined;
  }, [isSizesVisible]);

  return (
    <Container>
      <Sidebar />
      {isModalOpen && (
        <BanklyDetailsModal
          setIsOpen={handleSetIsModalOpen}
          transactionDetails={selectedTransaction as TransactionProps}
          isOpen={isModalOpen}
        />
      )}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.TransactionsContainer>
          <Styled.Title>
            <div>
              Transações{" "}
              <FiRefreshCw
                size={20}
                onClick={() => {
                  handleGetTransactions(currentPageSize, "initial");
                  handleGetBalance();
                }}
              />
            </div>
            <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
              <BorderlessInput
                type="text"
                id="search"
                name="search"
                onChange={() => {}}
                placeholder="Pesquisar"
                autoComplete="off"
              />
            </Styled.SearchForm>
          </Styled.Title>
          <Styled.BalanceContainer>
            Saldo atual:{"    "}
            {isLoadingBalance ? (
              <Skeleton />
            ) : (
              <Styled.Balance>
                {isBalanceVisible ? (
                  <>
                    {balance}
                    <FiEyeOff
                      size={18}
                      onClick={handleSetBalanceVisibility}
                    />{" "}
                  </>
                ) : (
                  <>
                    •••••••••
                    <FiEye size={18} onClick={handleSetBalanceVisibility} />
                  </>
                )}
              </Styled.Balance>
            )}
          </Styled.BalanceContainer>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.TransactionsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.TransactionsShimmer>
            ) : (
              <Styled.Ul>
                {transactions?.map((transaction) => {
                  return (
                    <Styled.Li
                      color={transaction.color || "lightblue"}
                      key={transaction.authenticationCode}
                    >
                      <Styled.CommonField>
                        <span>Canal:</span>
                        <strong>{transaction.channel}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Quantia:</span>
                        <strong>
                          {formatCurrencyByText(transaction.amount)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data:</span>
                        <strong>
                          {formatLocale(
                            transaction.createdAt,
                            "dd/MM/yyyy HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.StatusField
                        color={transaction.color || "lightblue"}
                      >
                        {transaction.operation}
                      </Styled.StatusField>
                      <Styled.ActionField>
                        {transaction.channel === "TED" && (
                          <FiZoomIn
                            size={27}
                            onClick={() =>
                              handleViewTransactionDetails(transaction)
                            }
                          />
                        )}
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}
            <Styled.BottomBar>
              <Styled.PageSize onClick={handleSetSizesVisibility}>
                <Styled.PageSizeValue>{currentPageSize}</Styled.PageSizeValue>
                {isSizesVisible === true && (
                  <Styled.Sizes ref={sizesContainerRef}>
                    <ul>
                      {sizes.map((option, index) => (
                        <li key={index}>
                          <option onClick={() => handleChooseSize(option)}>
                            {option}
                          </option>
                        </li>
                      ))}
                    </ul>
                  </Styled.Sizes>
                )}
              </Styled.PageSize>
              <Styled.Pagination>
                <Styled.PrevPage
                  isDisabled={!previousPage}
                  onClick={() =>
                    handleGetTransactions(currentPageSize, "previous")
                  }
                >
                  {"<"}
                </Styled.PrevPage>
                <Styled.NextPage
                  isDisabled={!nextPage}
                  onClick={() => handleGetTransactions(currentPageSize, "next")}
                >
                  {">"}
                </Styled.NextPage>
              </Styled.Pagination>
            </Styled.BottomBar>
          </Styled.ListContainer>
        </Styled.TransactionsContainer>
      </ContainerMain>
    </Container>
  );
};

export default Bankly;
