import React from "react";
import { AiOutlineBank } from "react-icons/ai";
import {
  FiCode,
  FiTerminal,
  FiCalendar,
  FiGitBranch,
  FiHash,
} from "react-icons/fi";
import { GiBank, GiIdCard } from "react-icons/gi";
import { RiCoinsLine } from "react-icons/ri";
import { formatLocale } from "../../utils/formatLocale";
import {
  cpfOrCnpjFormat,
  formatCurrencyByText,
} from "../../utils/inputAndTextMasks";

import Modal from "../Modal";

import * as Styled from "./styles";

interface TransactionProps {
  color?: string;
  channel: string;
  amount: number;
  operation: string;
  createdAt: string;
  authenticationCode: string;
  identifier: string;
  recipient?: {
    documentNumber?: string;
    account: {
      branch: string;
      number: string;
      ispb: string;
    };
  };
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  transactionDetails: TransactionProps;
}

const BanklyDetailsModal: React.FC<IModalProps> = ({
  isOpen,
  transactionDetails,
  setIsOpen,
}) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "650px", padding: "30px" }}
    >
      <Styled.Container>
        <Styled.DetailsContainer>
          <Styled.Strong>Dados da TED</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiHash size={18} />
              <h3>Código</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.authenticationCode}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiCode size={18} />
              <h3>Identificador</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.identifier}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiTerminal size={18} />
              <h3>Operação</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.operation}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <RiCoinsLine size={18} />
              <h3>Total</h3>
            </Styled.FieldTitle>
            <p>{formatCurrencyByText(transactionDetails.amount)}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiCalendar size={18} />
              <h3>Data</h3>
            </Styled.FieldTitle>
            <p>
              {formatLocale(
                transactionDetails.createdAt,
                "dd/MM/yyyy 'às' HH:mm",
              )}
            </p>
          </Styled.CommonField>

          <Styled.Strong>Dados do Beneficiário</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <GiIdCard size={18} />
              <h3>Documento</h3>
            </Styled.FieldTitle>
            <p>
              {cpfOrCnpjFormat(
                transactionDetails.recipient?.documentNumber as string,
              )}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <GiBank size={18} />
              <h3>Conta</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.recipient?.account.number}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <AiOutlineBank size={18} />
              <h3>ISPB</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.recipient?.account.ispb}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiGitBranch size={18} />
              <h3>Ramal</h3>
            </Styled.FieldTitle>
            <p>{transactionDetails.recipient?.account.branch}</p>
          </Styled.CommonField>
        </Styled.DetailsContainer>
      </Styled.Container>
    </Modal>
  );
};

export default BanklyDetailsModal;
