export function getOrderColor(status: string): string {
  switch (status) {
    case "Entregue":
      return "#00A759";
    case "Chegou na Entrega":
      return "#412800";
    case "Preparando":
      return "gray";
    case "Cancelado":
      return "var(--color-danger)";
    case "Aguardando":
      return "#e5e619";
    case "A caminho":
      return "#4c4cff";
    case "A caminho da retirada":
      return "#fd7e14";
    default:
      return "";
  }
}
