export function statusNameType(id) {
  const statusMap = {
    1: "AGUARDANDO",
    2: "PREPARANDO",
    3: "CHEGOU NA COLETA",
    4: "A CAMINHO",
    5: "ENTREGUE",
    6: "CANCELADO",
    7: "A CAMINHO DA RETIRADA",
    8: "CHEGOU NA ENTREGA",
  };

  const status = statusMap[id]?.toLowerCase() || "status desconhecido";

  return status.charAt(0).toUpperCase() + status.slice(1);
}
