import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { FiRefreshCw } from "react-icons/fi";
import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import { useAuth } from "../../hooks/AuthContext";

import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { formatCurrencyByText } from "../../utils/inputAndTextMasks";
import { formatLocale } from "../../utils/formatLocale";

import * as Styled from "./styles";

interface Transaction {
  id: number;
  user_id: null | number;
  transaction_type_id: number;
  transaction: string;
  amount: string;
  is_credit: true;
  balance: string;
  blocked_balance: string;
  created_at: string;
  color?: string;
}

interface TransactionsResponse {
  data: Transaction[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

const EstablishmentFinances: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>(
    [] as Transaction[],
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const [currentSize, setCurrentSize] = useState<null | number>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();

  const { company_id } = useAuth();

  const handleGetTransactions = useCallback(
    async (size = 6, page = 1) => {
      try {
        setIsLoading(true);
        const { data: transactionsResponse } =
          await api.get<TransactionsResponse>(
            `companies/${company_id}/transactions?page[size]=${size}&page[number]=${page}`,
          );

        transactionsResponse.data.forEach((transaction) => {
          switch (transaction.transaction) {
            case "Recarga cancelada":
              transaction.color = "#c53030";
              break;
            case "Recarga com Transferência Bancária":
              transaction.color = "#e5e619";
              break;
            case "Recarga com Boleto":
              transaction.color = "#DB7093";
              break;
            case "Pagamento de entrega":
              transaction.color = "#663399";
              break;
            case "Recarga com Cartão de Crédito":
              transaction.color = "blue";
              break;
            case "Pagamento de taxa":
              transaction.color = "olive";
              break;
            case "Estorno":
              transaction.color = "gray";
              break;
            default:
              break;
          }
        });
        setCurrentPage(page);
        setTotalPages(transactionsResponse.meta.last_page);
        setTransactions(transactionsResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast, company_id],
  );

  useEffect(() => {
    handleGetTransactions(8, 1);
  }, [handleGetTransactions]);

  return (
    <Container>
      <Sidebar />
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.TransactionsContainer>
          <Styled.TopBar>
            <Styled.Title>
              Financeiro - Extrato
              <FiRefreshCw
                size={20}
                onClick={() =>
                  handleGetTransactions(currentSize || 8, currentPage)
                }
              />{" "}
            </Styled.Title>
          </Styled.TopBar>
          <Styled.Strong>Histórico de transações:</Styled.Strong>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.TransactionsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.TransactionsShimmer>
            ) : (
              <Styled.Ul>
                {transactions.map((transaction) => {
                  return (
                    <Styled.Li
                      color={transaction.color || "lightblue"}
                      key={transaction.id}
                    >
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{transaction.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Tipo:</span>
                        <strong>
                          {transaction.is_credit ? "Crédito" : "Débito"}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Valor:</span>
                        <strong>
                          {formatCurrencyByText(transaction.amount)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Balance:</span>
                        <strong>
                          {formatCurrencyByText(transaction.balance)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data:</span>
                        <strong>
                          {formatLocale(
                            transaction.created_at,
                            "dd/MM/yyyy HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.StatusField
                        color={transaction.color || "lightblue"}
                      >
                        {transaction.transaction}
                      </Styled.StatusField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetTransactions}
                totalPages={totalPages}
                currentPage={currentPage}
                sizes={[8, 10, 20, 30]}
                currentSize={currentSize}
                setCurrentSize={setCurrentSize}
              />
            )}
          </Styled.ListContainer>
        </Styled.TransactionsContainer>
      </ContainerMain>
    </Container>
  );
};

export default EstablishmentFinances;
