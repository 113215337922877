/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import {
  FiArrowLeft,
  FiAtSign,
  FiBarChart2,
  FiBookmark,
  FiCalendar,
  FiColumns,
  FiCompass,
  FiDollarSign,
  FiMap,
  FiNavigation,
  FiPhone,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { GiIdCard, GiKeyCard, GiMusicalKeyboard } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import { RiMotorbikeFill, RiUserHeartLine } from "react-icons/ri";
import { BsCardHeading } from "react-icons/bs";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import ReadonlyInput from "../../components/ReadonlyInput";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import AutocompleteInput from "../../components/AutocompleteInput";
import InvisibleInput from "../../components/InvisibleInput";
import getValidationErrors from "../../utils/getValidationErrors";
import { formatLocale } from "../../utils/formatLocale";

import {
  cepFormat,
  cpfOrCnpjFormat,
  onChangePhoneMask,
  phoneFormat,
  rgFormat,
} from "../../utils/inputAndTextMasks";

import { DeliverymanById } from "../../@types/customTypes";

import * as Styled from "./styles";

interface StateResponse {
  id: number;
  name: string;
  uf: string;
}

interface DeliverymanStatusResponse {
  id: number;
  name: string;
}

interface VehicleTypesResponse {
  id: number;
  name: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface DeliverymanFormData {
  nickname: string;
  status: string;
  phone: string;
  state_id: string;
  cities: string | string[];
  email: string;
  vehicle: string;
  plate: string | null;
  renavam: string | null;
  name: string;
  mother_name: string;
  expiration_cnh_date: string;
  birthday: string;
  address_1: string;
  number_1: string;
  complement: string;
  neighborhood_1: string;
  postal_code_1: string;
  quantity_deliveries: string;
  withdrawal_blocked: boolean;
  uf_1: string;
  city_1: string;
  latitude_1: string;
  longitude_1: string;
}

function buildISOStringDate(date: string) {
  const [day, month, year] = date.split("/").map(Number);
  // month in JS object starts from 0, January = 0, Febraury = 1 and so on, thats why i put month - 1 in Date obj.
  let format = new Date(year, month - 1, day);
  const userTimezoneOffset = format.getTimezoneOffset() * 60000;

  format = new Date(format.getTime() - userTimezoneOffset);
  return format.toISOString();
}

const DeliverymansById: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [deliverymanData, setDeliverymanData] = useState<DeliverymanById>(
    {} as DeliverymanById,
  );
  const [childrenStateUpdater, setChildrenStateUpdater] = useState(0);
  const [states, setStates] = useState([{ label: "", value: "" }]);
  const [cities, setCities] = useState([{ label: "", value: "" }]);
  const [deliverymanStatus, setDeliverymanStatus] = useState([
    { label: "", value: "" },
  ]);
  const [vehicleTypes, setVehicleTypes] = useState([{ label: "", value: "" }]);
  const [showVehicleDetails, setShowVehicleDetails] = useState(true);

  const { isSidebarMaximized } = useSidebar();
  const params: { id: string } = useParams();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleResetPass = useCallback(async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Resetar senha",
      html: `Tem certeza que deseja resetar a senha?`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "var(--color-primary)",
      cancelButtonColor: "var(--color-danger)",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    });

    if (isConfirmed) {
      setIsLoading(true);
      try {
        const data = {
          email: deliverymanData?.user?.email,
          phone: deliverymanData?.phones,
          type: "deliverymans",
        };
        await api.post(`/password/default_reset`, data);

        addToast("Senha resetada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Erro ao resetar senha", {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [addToast, deliverymanData?.phones, deliverymanData?.user?.email]);

  const handleShowVehicleDetails = useCallback((vehicleTypeId: number) => {
    if (!vehicleTypeId) {
      return;
    }

    setShowVehicleDetails(vehicleTypeId < 3);
  }, []);

  const getCities = useCallback(async (state_id: number, isUpdate = true) => {
    if (!state_id) {
      return;
    }

    if (isUpdate) {
      setChildrenStateUpdater((state) => state + 1);
    }

    setShowSpinner(true);
    setCities([{ label: "", value: "" }]);
    formRef.current?.setData({
      cities: [],
    });
    const { data: CitiesData } = await api.get(`/cities/${state_id}`);
    const MappedCities = CitiesData.map((city: CitiesResponse) => {
      return {
        label: city.name,
        value: city.id,
      };
    });
    setCities(MappedCities);

    if (isUpdate) {
      setShowSpinner(false);
    }
  }, []);

  const submitDeliverymanUpdateForm = useCallback(
    async (data: DeliverymanFormData) => {
      try {
        data.cities = JSON.parse(`[${data.cities}]`);
        data.renavam = data.renavam || null;
        data.plate = data.plate || null;
        const initialCities = deliverymanData.cities
          .map((city) => city.id)
          .sort();
        const birthday = buildISOStringDate(data.birthday);
        const expiration_cnh_date =
          data.expiration_cnh_date === "N/A" || data.expiration_cnh_date === ""
            ? null
            : buildISOStringDate(data.expiration_cnh_date);

        const deliverymanInfo = {
          mother_name: data.mother_name,
          nickname: data.nickname,
          name: data.name,
          birthday,
          quantity_deliveries: data.quantity_deliveries,
          withdrawal_blocked: data.withdrawal_blocked,
        };

        if (expiration_cnh_date) {
          // @ts-ignore
          deliverymanInfo.expiration_cnh_date = expiration_cnh_date;
        }

        await api.put(`deliverymans/${params.id}`, deliverymanInfo);

        if (deliverymanData.user.email !== data.email) {
          await api.put(`deliverymans/${params.id}/email`, {
            email: data.email,
          });
        }

        if (deliverymanData.deliveryman_status_id !== Number(data.status)) {
          await api.put(`deliverymans/${params.id}/status`, {
            deliveryman_status_id: data.status,
          });
        }

        if (deliverymanData.phones[0] !== data.phone) {
          await api.put(`deliverymans/${params.id}/phone`, {
            phone: data.phone,
          });
        }

        if (JSON.stringify(initialCities) !== JSON.stringify(data.cities)) {
          await api.put(`deliverymans/${params.id}/cities`, {
            cities: data.cities,
          });
        }

        if (
          deliverymanData.address.address !== data.address_1 ||
          deliverymanData.address.complement !== data.complement ||
          deliverymanData.address.number !== data.number_1
        ) {
          const address = {
            address: data.address_1,
            number: data.number_1,
            complement: data.complement,
            neighborhood: data.neighborhood_1,
            postal_code: data.postal_code_1.replace(/\D/g, ""),
            uf: data.uf_1,
            city: data.city_1,
            latitude: data.latitude_1,
            longitude: data.longitude_1,
          };
          await api.put(`deliverymans/${params.id}/address`, { address });
        }

        if (
          deliverymanData.vehicle.vehicle_type_id !== Number(data.vehicle) ||
          deliverymanData.vehicle.renavam !== data.renavam ||
          deliverymanData.vehicle.plate !== data.plate
        ) {
          await api.put(`deliverymans/${params.id}/vehicle`, {
            vehicle_type_id: data.vehicle,
            ...(Number(data.vehicle) < 3 && {
              renavam: data.renavam,
              plate: data.plate,
            }),
          });
        }

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Alterações realizadas com sucesso, aguarde o tempo de cache dos nossos servidores!",
        });
      } catch (error: any) {
        setShowSpinner(false);
        setIsLoading(false);
        const errorResponse = error?.response;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Falha atualizar, ${
            errorResponse
              ? Object.values(
                  JSON.parse(errorResponse.request?.responseText),
                ).join(" ")
              : error.message
          }`,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [params.id, deliverymanData],
  );

  const verifyFormSubmit = useCallback(
    async (data: DeliverymanFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        data.phone = data.phone.replace(/\D/g, "");
        // @ts-ignore
        data.cities = data.cities.map(String).join(", ");

        const schema = Yup.object().shape({
          // Dados do entregador
          name: Yup.string().min(2, "Nome inválido"),
          nickname: Yup.string().min(3, "Apelido inválido"),
          status: Yup.string().required("Campo obrigatório"),
          birthday: Yup.string()
            .min(10, "Data de nascimento inválido")
            .max(10, "Data de nascimento inválido"),
          phone: Yup.string().min(10, "Telefone inválido"),
          expiration_cnh_date: Yup.string().when("vehicle", {
            is: (val: number) => val < 3,
            then: Yup.string()
              .min(10, "A data de expiração do CNH é obrigatório")
              .max(10, "A data de expiração do CNH é inválido"),
          }),
          mother_name: Yup.string().min(3, "Nome da mãe está inválido"),
          quantity_deliveries: Yup.number()
            .min(1, "A quantidade de entregas de motoboys deve ser no mínimo 1")
            .max(6, "A quantidade de entregas de motoboys deve ser no máximo 6")
            .required("A quantidade de entregas de motoboys é obrigatória")
            .typeError(
              "A quantidade de entregas de motoboys deve ser um número válido",
            ),
          withdrawal_blocked: Yup.boolean()
            .required("Campo obrigatório")
            .typeError("Este campo deve ser selecionado como Sim ou Não"),
          state_id: Yup.string().required("Campo obrigatório"),
          cities: Yup.string().required("Campo obrigatório"),

          // Endereço do entregador
          complement: Yup.string(),
          latitude_1: Yup.string().required(),
          longitude_1: Yup.string().required(),
          number_1: Yup.string().required("Campo obrigatório"),
          // Dados de segurança
          email: Yup.string()
            .email("E-mail inválido")
            .required("Campo obrigatório"),
          vehicle: Yup.string().required("Campo obrigatório"),
          renavam: Yup.string().when("vehicle", {
            is: (val: number) => val < 3,
            then: Yup.string().min(3, "Renavam inválido"),
          }),
          plate: Yup.string().when("vehicle", {
            is: (val: number) => val < 3,
            then: Yup.string().min(3, "Placa inválida"),
          }),
        });
        await schema.validate(data, { abortEarly: false });
        submitDeliverymanUpdateForm(data);
      } catch (err: any) {
        setShowSpinner(false);
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [submitDeliverymanUpdateForm],
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: StatesData } = await api.get("/states");
        const MappedStates = StatesData.map((state: StateResponse) => {
          return {
            label: state.name,
            value: state.id,
          };
        });
        setStates(MappedStates);

        const { data: deliverymanStatusData } = await api.get(
          "/deliverymanstatus",
        );
        const MappedDeliverymanStatus = deliverymanStatusData.map(
          (status: DeliverymanStatusResponse) => {
            return {
              label: status.name,
              value: status.id,
            };
          },
        );
        setDeliverymanStatus(MappedDeliverymanStatus);

        const { data: vehicleTypesData } = await api.get("/vehicletypes");
        const MappedVehicleTypes = vehicleTypesData.map(
          (type: VehicleTypesResponse) => {
            return {
              label: type.name,
              value: type.id,
            };
          },
        );
        setVehicleTypes(MappedVehicleTypes);

        const { data: deliverymanDataResponse }: { data: DeliverymanById } =
          await api.get(`deliverymans/${params.id}`);

        setDeliverymanData(deliverymanDataResponse);

        setShowVehicleDetails(
          deliverymanDataResponse.vehicle.vehicle_type_id < 3,
        );

        if (deliverymanDataResponse.cities[0]) {
          await getCities(deliverymanDataResponse.cities[0].state_id, false);
        }

        formRef.current?.setData({
          name: deliverymanDataResponse.user.name,
          nickname: deliverymanDataResponse.nickname,
          birthday: formatLocale(
            deliverymanDataResponse.birthday,
            "dd/MM/yyyy",
          ),
          status: deliverymanDataResponse.deliveryman_status_id,
          state_id: deliverymanDataResponse.cities[0]?.state_id,
          cities: deliverymanDataResponse.cities.map((city) => city.id),
          vehicle: deliverymanDataResponse.vehicle.vehicle_type_id,
          rg: rgFormat(deliverymanDataResponse.rg),
          cpf: cpfOrCnpjFormat(deliverymanDataResponse.cpf),
          cnh: deliverymanDataResponse.cnh || "N/A",
          expiration_cnh_date: deliverymanDataResponse.expiration_cnh_date
            ? formatLocale(
                deliverymanDataResponse.expiration_cnh_date,
                "dd/MM/yyyy",
              )
            : "N/A",
          phone: deliverymanDataResponse.phones[0]
            ? phoneFormat(deliverymanDataResponse.phones[0])
            : "N/A",
          mother_name: deliverymanDataResponse.mother_name,
          quantity_deliveries: deliverymanDataResponse.quantity_deliveries,
          withdrawal_blocked: deliverymanDataResponse.withdrawal_blocked,
          complement: deliverymanDataResponse.address.complement || "N/A",
          email: deliverymanDataResponse.user.email,
          ...(deliverymanDataResponse.vehicle.vehicle_type_id < 3 && {
            renavam: deliverymanDataResponse.vehicle.renavam,
            plate: deliverymanDataResponse.vehicle.plate,
          }),
          address_1: deliverymanDataResponse.address.address,
          number_1: deliverymanDataResponse.address.number,
          neighborhood_1: deliverymanDataResponse.address.neighborhood,
          postal_code_1: cepFormat(deliverymanDataResponse.address.postal_code),
          uf_1: deliverymanDataResponse.address.state,
          city_1: deliverymanDataResponse.address.city,
          latitude_1: deliverymanDataResponse.address.latitude,
          longitude_1: deliverymanDataResponse.address.longitude,
          autocomplete_1: `${deliverymanDataResponse.address.address}${
            deliverymanDataResponse.address.number &&
            `, ${deliverymanDataResponse.address.number} - ${deliverymanDataResponse.address.neighborhood}, ${deliverymanDataResponse.address.city} - ${deliverymanDataResponse.address.state}`
          }`,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [params.id, getCities, addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.DeliverymanContainer>
          <Styled.Link to="/gerenciar/entregadores">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do entregador</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
              <Input
                type="text"
                id="nickname"
                name="nickname"
                placeholder="Apelido"
                icon={FiUsers}
              />
              <Select
                id="status"
                name="status"
                placeholder="Status"
                formRef={formRef}
                options={deliverymanStatus}
                icon={FiBarChart2}
                isSearchable={false}
              />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="birthday"
                name="birthday"
                placeholder="Data de nascimento"
                icon={FiCalendar}
              />
              <Input
                type="text"
                id="phone"
                name="phone"
                placeholder="Telefone"
                onChange={onChangePhoneMask}
                icon={FiPhone}
              />
              <ReadonlyInput
                type="text"
                id="rg"
                name="rg"
                placeholder="RG"
                icon={GiIdCard}
              />
            </Styled.Row>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="cpf"
                name="cpf"
                placeholder="CPF"
                icon={FiColumns}
              />
              <ReadonlyInput
                type="text"
                id="cnh"
                name="cnh"
                placeholder="CNH"
                icon={GiKeyCard}
              />
              <Input
                type="text"
                id="expiration_cnh_date"
                name="expiration_cnh_date"
                placeholder="Vencimento da CNH"
                icon={FiCalendar}
              />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="mother_name"
                name="mother_name"
                placeholder="Nome da mãe"
                icon={RiUserHeartLine}
              />
              <Select
                id="state_id"
                name="state_id"
                placeholder="Estado"
                formRef={formRef}
                options={states}
                icon={FiBookmark}
                onChange={(option: any) => getCities(option?.value)}
              />
            </Styled.Row>
            <Styled.Row>
              <Select
                id="cities"
                name="cities"
                placeholder="Atuação"
                formRef={formRef}
                options={cities}
                childrenStateUpdater={childrenStateUpdater}
                icon={FiNavigation}
                isMulti
              />

              <Input
                type="number"
                id="quantity_deliveries"
                name="quantity_deliveries"
                placeholder="Quantidade de Entregas Simultâneas"
                icon={RiMotorbikeFill}
                max={6}
                min={1}
              />

              <Select
                id="withdrawal_blocked"
                name="withdrawal_blocked"
                placeholder="Saque Bloqueado"
                formRef={formRef}
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                icon={FiDollarSign}
                isSearchable={false}
              />
            </Styled.Row>
            <Styled.Strong>Endereço do entregador</Styled.Strong>
            <Styled.Row>
              <AutocompleteInput
                type="text"
                id="autocomplete_1"
                name="autocomplete_1"
                index={1}
                placeholder="Endereço"
                icon={FiCompass}
                formRef={formRef}
              />
              <Input
                type="text"
                id="complement"
                name="complement"
                placeholder="Complemento"
                icon={FiMap}
              />
              <InvisibleInput type="text" id="address_1" name="address_1" />
              <InvisibleInput type="text" id="latitude_1" name="latitude_1" />
              <InvisibleInput type="text" id="longitude_1" name="longitude_1" />
              <InvisibleInput
                type="text"
                id="postal_code_1"
                name="postal_code_1"
              />
              <InvisibleInput type="text" id="number_1" name="number_1" />
              <InvisibleInput
                type="text"
                id="neighborhood_1"
                name="neighborhood_1"
              />
              <InvisibleInput type="text" id="city_1" name="city_1" />
              <InvisibleInput type="text" id="uf_1" name="uf_1" />
            </Styled.Row>
            <Styled.Strong>Dados de segurança</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />
              <Select
                id="vehicle"
                name="vehicle"
                placeholder="Veículo"
                formRef={formRef}
                options={vehicleTypes}
                icon={AiOutlineCar}
                onChange={(option: any) =>
                  handleShowVehicleDetails(option?.value)
                }
                isSearchable={false}
              />
              {showVehicleDetails && (
                <Input
                  type="text"
                  id="renavam"
                  name="renavam"
                  placeholder="Renavam"
                  icon={BsCardHeading}
                />
              )}
            </Styled.Row>
            {showVehicleDetails && (
              <Styled.Row>
                <Input
                  type="text"
                  id="plate"
                  name="plate"
                  placeholder="Placa do veículo"
                  icon={GiMusicalKeyboard}
                />
              </Styled.Row>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="button"
                onClick={handleResetPass}
                customColor="#ff9300"
                content={isLoading ? "Carregando..." : "Resetar senha"}
                disabled={isLoading}
              />
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.DeliverymanContainer>
      </ContainerMain>
    </Container>
  );
};

export default DeliverymansById;
