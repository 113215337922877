/* eslint-disable */
import React, { useCallback, useMemo } from "react";
import { OptionTypeBase } from "react-select";

import * as Styled from "./styles";
import { ReactSelect } from "./styles";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  updateList(
    size: number,
    page: number,
    thirdParam?: number | string,
  ): Promise<void>;
  companyId?: null | number;
  searchQuery?: string;
  sizes?: number[];
  currentSize?: number | null;
  setCurrentSize?: React.Dispatch<React.SetStateAction<number | null>>;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  updateList,
  companyId,
  searchQuery,
  sizes,
  currentSize = 10,
  setCurrentSize,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = useCallback(
    (page: number) => {
      if (page < 1 || page > totalPages || page === currentPage) {
        return;
      }

      if (companyId) {
        updateList(currentSize || 8, page, companyId);
      } else if (searchQuery) {
        updateList(currentSize || 8, page, searchQuery);
      } else {
        updateList(currentSize || 8, page);
      }
    },
    [totalPages, updateList, currentPage, companyId, searchQuery, currentSize],
  );

  const handleChooseSize = useCallback(
    (option: OptionTypeBase): void => {
      const size = option.value;

      if (setCurrentSize) {
        // handleSetSizesVisibility();
        setCurrentSize(size);

        if (companyId) {
          updateList(size, 1, companyId);
        } else if (searchQuery) {
          updateList(size, 1, searchQuery);
        } else {
          updateList(size, 1);
        }
      }
    },
    [companyId, searchQuery, updateList, setCurrentSize],
  );

  const sizesList = useMemo(() => {
    return sizes?.map((size) => {
      return { value: size, label: size };
    });
  }, [sizes]);

  return (
    <>
      {totalPages > 0 && (
        <Styled.Container>
          {sizes && (
            <ReactSelect
              classNamePrefix="react-select"
              defaultValue={
                currentSize
                  ? { value: currentSize, label: currentSize }
                  : { value: 8, label: 8 }
              }
              isSearchable={false}
              options={sizesList}
              onChange={handleChooseSize}
            />
          )}
          <Styled.PaginationContainer>
            <Styled.Ul>
              {currentPage > 2 && (
                <Styled.Li onClick={() => handleChangePage(1)}>
                  {"<<"}
                </Styled.Li>
              )}
              {currentPage > 1 && (
                <Styled.Li onClick={() => handleChangePage(currentPage - 1)}>
                  {"<"}
                </Styled.Li>
              )}
              {pageNumbers.map((page, index) => {
                if (
                  index > currentPage + 3 ||
                  (index < currentPage - 1 && index + 6 <= totalPages)
                ) {
                  return null;
                }
                return (
                  <Styled.Li
                    key={index}
                    onClick={() => handleChangePage(page)}
                    isCurrentPage={page === currentPage}
                  >
                    {page}
                  </Styled.Li>
                );
              })}
              {currentPage < totalPages - 4 && (
                <Styled.Li onClick={() => handleChangePage(currentPage + 1)}>
                  {">"}
                </Styled.Li>
              )}
              {currentPage < totalPages - 5 && (
                <Styled.Li onClick={() => handleChangePage(totalPages)}>
                  {">>"}
                </Styled.Li>
              )}
            </Styled.Ul>
          </Styled.PaginationContainer>
        </Styled.Container>
      )}
    </>
  );
};

export default Pagination;
