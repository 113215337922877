import React, { useEffect, useState, useCallback, useRef } from "react";
import { FiCalendar, FiRefreshCw } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";

import { useAuth } from "../../hooks/AuthContext";

import api from "../../services/api";

import { formatCurrencyByText } from "../../utils/inputAndTextMasks";

import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Container from "../../components/Container";
import OnlineDeliverymansModal from "../../components/OnlineDeliverymansModal";
import { DateStateObject } from "../../components/DatePickerTooltip";
import OrdersQuantityModal from "../../components/OrdersQuantityModal";

import { useSidebar } from "../../hooks/SidebarContext";

import { formatLocale } from "../../utils/formatLocale";

import {
  OnlineDeliverymansResponse,
  OnlineDeliveryman,
} from "../../@types/customTypes";

import * as Styled from "./styles";
import AverageDeliveryTimeModal from "../../components/AverageDeliveryTimeModal";
import LoadingSpinner from "../../components/LoadingSpinner";

interface CompanyData {
  balance: number;
}

interface OrdersQuantityResponseProps {
  [key: string]: number | string;
}

interface OrdersQuantityProps {
  status_name: string;
  quantity: number;
  color: string;
}

const Dashboard: React.FC = () => {
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [dashboardData, setDashboardData] = useState<any | null>(null);
  const [onlineDeliverymans, setOnlineDeliverymans] = useState<
    null | OnlineDeliveryman[]
  >(null);
  const [isDinamicTable, setIsDinamicTable] = useState<null | boolean>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [
    onlineDeliverymansModalVisibility,
    setOnlineDeliverymansModalVisibility,
  ] = useState(false);
  const [isAverageDeliveryTimeModalOpen, setIsAverageDeliveryTimeModalOpen] =
    useState(false);
  const [averageDeliveryTime, setAverageDeliveryTime] = useState("00:00:00");
  const [ordersQuantity, setOrdersQuantity] = useState<OrdersQuantityProps[]>([
    { status_name: "Total", quantity: 0, color: "#393939" },
  ]);
  const [isOrdersQuantityModalOpen, setIsOrdersQuantityModalOpen] =
    useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const { company_id, role, companyLocation, user_id } = useAuth();
  const { addToast } = useToasts();
  const { isSidebarMaximized } = useSidebar();

  const tooltipAverageTimeRef = useRef<any>(null);
  const tooltipOrdersQuantityRef = useRef<any>(null);

  const dateAverageTimeReqParams = useRef<null | DateStateObject>(null);
  const dateOrdersQuantityReqParams = useRef<null | DateStateObject>(null);

  const handleSetIsAverageDeliveryTimeModalOpen = useCallback(() => {
    setIsAverageDeliveryTimeModalOpen((state) => !state);
  }, []);

  const handleSetIsOrdersQuantityModalOpen = useCallback(() => {
    setIsOrdersQuantityModalOpen((state) => !state);
  }, []);

  const handleSetOnlineDeliverymansModalVisibility = useCallback(() => {
    setOnlineDeliverymansModalVisibility((state) => !state);
  }, []);

  const handleGetAverageDeliveryTime = useCallback(
    async (isUpdate?: boolean, cityId?: number, maxDistance?: number) => {
      try {
        if (isUpdate) {
          setIsLoading(true);
          tooltipAverageTimeRef.current?.globalHide();
        }

        const startDate = formatLocale(
          dateAverageTimeReqParams.current?.periodo_inicio || new Date(),
          "yyyy-MM-dd",
        );

        const endDate = formatLocale(
          dateAverageTimeReqParams.current?.periodo_fim || new Date(),
          "yyyy-MM-dd",
        );

        let averageDeliveryTimeUrl = `orders/reports/average-delivery-time?user_id=${user_id}&start_date=${startDate}&end_date=${endDate}`;

        if (cityId) {
          setShowSpinner(true);
          averageDeliveryTimeUrl += `&city=${cityId}`;
        }

        if (maxDistance) {
          averageDeliveryTimeUrl += `&max_distance=${maxDistance}`;
        }

        const { data } = await api.get(averageDeliveryTimeUrl);

        setAverageDeliveryTime(data.average_delivery_time);
      } catch (err) {
        if (isUpdate)
          addToast("Ocorreu um erro ao carregar dados!", {
            appearance: "warning",
            autoDismiss: true,
          });
      }

      if (cityId) {
        setShowSpinner(false);
      }

      if (isUpdate) setIsLoading(false);
    },
    [user_id, addToast],
  );

  const handleGetOrdersQuantity = useCallback(
    async (isUpdate?: boolean) => {
      try {
        if (isUpdate) {
          setIsLoading(true);
          tooltipOrdersQuantityRef.current?.globalHide();
        }

        const startDate = formatLocale(
          dateOrdersQuantityReqParams.current?.periodo_inicio || new Date(),
          "yyyy-MM-dd",
        );

        const endDate = formatLocale(
          dateOrdersQuantityReqParams.current?.periodo_fim || new Date(),
          "yyyy-MM-dd",
        );

        const { data }: { data: OrdersQuantityResponseProps } = await api.get(
          `orders/reports/status?user_id=${user_id}&start_date=${startDate}&end_date=${endDate}`,
        );

        let total = 0;

        const formattedOrdersQuantityData = Object.keys(data).map((key) => {
          total += Number(data[key]);
          let color;

          switch (key) {
            case "Entregue":
              color = "#00A759";
              break;
            case "Chegou na Entrega":
              color = "#412800";
              break;
            case "Preparando":
              color = "gray";
              break;
            case "Cancelado":
              color = "var(--color-danger)";
              break;
            case "Aguardando":
              color = "#e5e619";
              break;
            case "A caminho":
              color = "#4c4cff";
              break;
            case "Pronto":
              color = "#fd7e14";
              break;
            default:
              color = "#393939";
              break;
          }

          return { status_name: key, quantity: Number(data[key]), color };
        });

        setOrdersQuantity([
          ...formattedOrdersQuantityData,
          { status_name: "Total", quantity: total, color: "#393939" },
        ]);
      } catch (err) {
        if (isUpdate)
          addToast("Ocorreu um erro ao carregar dados!", {
            appearance: "warning",
            autoDismiss: true,
          });
      }

      if (isUpdate) setIsLoading(false);
    },
    [user_id, addToast],
  );

  const handleGetOnlineDeliverymans = useCallback(async () => {
    const { data: deliverymansResponseData } =
      await api.get<OnlineDeliverymansResponse>("deliverymans/online");
    setOnlineDeliverymans(deliverymansResponseData.data);
  }, []);

  const hangleGetCompanyData = useCallback(async () => {
    const { data: companyResponseData } = await api.get(
      `companies/${company_id}`,
    );

    // REMOVER IF APOS SOLUÇÃO DEFINITIVA
    if (companyResponseData.status.name !== "Ativo") {
      localStorage.clear();
      window.location.reload();
    }

    setCompanyData(companyResponseData);
  }, [company_id]);

  const handleGetRainFlagInfo = useCallback(async () => {
    const { data: rainCostResponseData } = await api.get(
      `configs/rain/${companyLocation?.companyCityId}`,
    );
    setIsDinamicTable(rainCostResponseData.rainCostPercent > 0);
  }, [companyLocation?.companyCityId]);

  const handleGetDashboardFullData = useCallback(async () => {
    const { data: dashboardFullData } = await api.get("dashboard");
    setDashboardData(dashboardFullData);
  }, []);

  const handleGetDashboardData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (
        role !== process.env.REACT_APP_ADMIN_ROLE &&
        role !== process.env.REACT_APP_OPERATOR_ROLE &&
        role !== process.env.REACT_APP_FINANCIAL_ROLE &&
        role !== process.env.REACT_APP_MAIN_OFFICE_ROLE
      ) {
        await hangleGetCompanyData();
        await handleGetRainFlagInfo();
      } else {
        await handleGetOnlineDeliverymans();
        await handleGetDashboardFullData();
        if (role === process.env.REACT_APP_MAIN_OFFICE_ROLE) {
          await handleGetAverageDeliveryTime();
          await handleGetOrdersQuantity();
        }
      }
      setIsLoading(false);
    } catch (e) {
      addToast("Ocorreu um erro ao carregar dados!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, [
    role,
    addToast,
    handleGetOnlineDeliverymans,
    hangleGetCompanyData,
    handleGetRainFlagInfo,
    handleGetDashboardFullData,
    handleGetAverageDeliveryTime,
    handleGetOrdersQuantity,
  ]);

  useEffect(() => {
    handleGetDashboardData();
  }, [handleGetDashboardData]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <AverageDeliveryTimeModal
        setIsOpen={handleSetIsAverageDeliveryTimeModalOpen}
        isOpen={isAverageDeliveryTimeModalOpen}
        filterPeriod={{
          startDate: formatLocale(
            dateAverageTimeReqParams.current?.periodo_inicio || new Date(),
            "dd/MM/yyyy",
          ),
          endDate: formatLocale(
            dateAverageTimeReqParams.current?.periodo_fim || new Date(),
            "dd/MM/yyyy",
          ),
        }}
        setShowSpinner={setShowSpinner}
        handleGetAverageDeliveryTime={handleGetAverageDeliveryTime}
        averageDeliveryTime={averageDeliveryTime}
      />
      <OrdersQuantityModal
        setIsOpen={handleSetIsOrdersQuantityModalOpen}
        isOpen={isOrdersQuantityModalOpen}
        ordersQuantityData={ordersQuantity}
        filterPeriod={{
          startDate: formatLocale(
            dateOrdersQuantityReqParams.current?.periodo_inicio || new Date(),
            "dd/MM/yyyy",
          ),
          endDate: formatLocale(
            dateOrdersQuantityReqParams.current?.periodo_fim || new Date(),
            "dd/MM/yyyy",
          ),
        }}
      />
      <OnlineDeliverymansModal
        deliverymansData={onlineDeliverymans}
        setIsOpen={handleSetOnlineDeliverymansModalVisibility}
        isOpen={onlineDeliverymansModalVisibility}
      />
      {/* {role === process.env.REACT_APP_MAIN_OFFICE_ROLE && (
        <>
          <DatePickerTooltip
            tooltipId="average-time-data-tooltip"
            tooltipRef={tooltipAverageTimeRef}
            reqParamsRef={dateAverageTimeReqParams}
            doneButtonLabel="Salvar"
            onClickDone={handleGetAverageDeliveryTime}
          />
          <DatePickerTooltip
            tooltipId="orders-quantity-data-tooltip"
            tooltipRef={tooltipOrdersQuantityRef}
            reqParamsRef={dateOrdersQuantityReqParams}
            doneButtonLabel="Salvar"
            onClickDone={handleGetOrdersQuantity}
          />
        </>
      )} */}
      <Styled.Main isSidebarMaximized={isSidebarMaximized}>
        {role !== process.env.REACT_APP_ADMIN_ROLE &&
        role !== process.env.REACT_APP_OPERATOR_ROLE &&
        role !== process.env.REACT_APP_FINANCIAL_ROLE &&
        role !== process.env.REACT_APP_MAIN_OFFICE_ROLE ? (
          <>
            <Styled.NormalCard isDinamic={!!isDinamicTable}>
              <Styled.CardTitle>Tabela de referência</Styled.CardTitle>
              {isDinamicTable && (
                <Styled.DinamicText>Alta demanda</Styled.DinamicText>
              )}
              <Styled.CardStrong>Valores base:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen isDinamic={!!isDinamicTable}>
                  {isDinamicTable ? "Dinâmico" : "Padrão"}
                </Styled.CardGreen>
              </Styled.GreenContainer>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Saldo de créditos</Styled.CardTitle>
              <Styled.CardStrong>Seu saldo total é:</Styled.CardStrong>
              <Styled.GreenContainer onClick={handleGetDashboardData}>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    companyData?.balance.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} />
              </Styled.GreenContainer>
            </Styled.NormalCard>
            <Styled.NormalCard>
              <Styled.CardTitleContainer>
                <Styled.CardTitle>Tempo médio de entrega</Styled.CardTitle>

                <FiCalendar
                  size={20}
                  data-tip
                  data-for="average-time-data-tooltip"
                  data-event="click"
                />
              </Styled.CardTitleContainer>
              <Styled.CardStrong>Tempo médio:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton />
                      <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    averageDeliveryTime
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
              <Styled.ViewMore
                onClick={handleSetIsAverageDeliveryTimeModalOpen}
              >
                Filtrar por
              </Styled.ViewMore>
            </Styled.NormalCard>
            <Styled.NormalCard>
              <Styled.CardTitleContainer>
                <Styled.CardTitle>Quantidade de pedidos</Styled.CardTitle>

                <FiCalendar
                  size={20}
                  data-tip
                  data-for="orders-quantity-data-tooltip"
                  data-event="click"
                />
              </Styled.CardTitleContainer>
              <Styled.CardStrong>Seu total é:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    ordersQuantity.find(
                      (status) => status.status_name === "Total",
                    )?.quantity
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
              <Styled.ViewMore onClick={handleSetIsOrdersQuantityModalOpen}>
                Detalhar
              </Styled.ViewMore>
            </Styled.NormalCard>
          </>
        ) : (
          <>
            <Styled.NormalCard>
              <Styled.CardTitle>Entregadores online</Styled.CardTitle>
              <Styled.CardStrong>Total de Entregadores:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    dashboardData?.totalDeliverymansOnline
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
              <Styled.ViewMore
                onClick={handleSetOnlineDeliverymansModalVisibility}
                disabled={!dashboardData?.totalDeliverymansOnline}
              >
                Ver mais
              </Styled.ViewMore>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Entregadores</Styled.CardTitle>
              <Styled.CardStrong>Entregadores Ativos:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    `${dashboardData.activeDeliverymans} de ${dashboardData.totalDeliverymans}`
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Comerciantes</Styled.CardTitle>
              <Styled.CardStrong>Comerciantes Ativos:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    `${dashboardData.activeCompanies} de ${dashboardData.totalCompanies}`
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Transacionado</Styled.CardTitle>
              <Styled.CardStrong>Total transacionado:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    formatCurrencyByText(dashboardData.totalTransaction)
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Depósitos</Styled.CardTitle>
              <Styled.CardStrong>Total Depósitos:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    formatCurrencyByText(dashboardData.totalCharge)
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
            </Styled.NormalCard>

            <Styled.NormalCard>
              <Styled.CardTitle>Retiradas</Styled.CardTitle>
              <Styled.CardStrong>Total Retiradas:</Styled.CardStrong>
              <Styled.GreenContainer noHover>
                <Styled.CardGreen>
                  {isLoading ? (
                    <Styled.Shimmer>
                      <Skeleton /> <Skeleton />
                    </Styled.Shimmer>
                  ) : (
                    formatCurrencyByText(dashboardData.totalWithdraw)
                  )}
                </Styled.CardGreen>
                <FiRefreshCw size={20} onClick={handleGetDashboardData} />
              </Styled.GreenContainer>
            </Styled.NormalCard>
          </>
        )}
      </Styled.Main>
    </Container>
  );
};

export default Dashboard;
