import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit, FiRefreshCw, FiZoomIn } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { FormHandles } from "@unform/core";
import { debounce } from "lodash";

import { useSidebar } from "../../hooks/SidebarContext";
import api from "../../services/api";
import { formatLocale } from "../../utils/formatLocale";
import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import DeliverymanDetailsModal from "../../components/DeliverymanDetailsModal";

import { DeliverymanFromGetDeliverymans } from "../../@types/customTypes";

import * as Styled from "./styles";
import {
  generateSearchVariations,
  normalizePhoneNumber,
  normalizeString,
} from "../../utils/fomartSearchs";

export interface DeliverymansResponse {
  data: DeliverymanFromGetDeliverymans[];
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface DeliverymanImages {
  src: string;
  alt: string;
}

const Deliverymans: React.FC = () => {
  const [deliverymans, setDeliverymans] = useState<
    DeliverymanFromGetDeliverymans[]
  >([] as DeliverymanFromGetDeliverymans[]);
  const [selectedDeliveryman, setSelectedDeliveryman] =
    useState<DeliverymanFromGetDeliverymans>(
      {} as DeliverymanFromGetDeliverymans,
    );
  const [deliverymanImages, setDeliverymanImages] = useState<
    DeliverymanImages[]
  >({} as DeliverymanImages[]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetDeliverymans = useCallback(
    async (size = 8, page = 1, searchQuery = "") => {
      try {
        setIsLoading(true);
        const searchValue = normalizeString(searchQuery);
        const searchVariations = generateSearchVariations(searchValue);
        const normalizedPhone = normalizePhoneNumber(searchValue);

        const userNameSearchQuery = searchVariations
          .map((variation) => `user.name:${variation}`)
          .join(";");

        const finalSearchQuery = `${userNameSearchQuery};user.email:${searchValue};phones.number:${normalizedPhone};cpf:${normalizedPhone}`;

        const { data: deliverymansResponse } =
          await api.get<DeliverymansResponse>(
            `deliverymans?page[size]=${size}&page[number]=${page}&search=${finalSearchQuery}`,
          );

        deliverymansResponse.data.forEach((deliveryman) => {
          switch (deliveryman.status.name) {
            case "Ativo":
              deliveryman.color = "var(--color-primary)";
              break;
            case "Aguardando aprovação":
              deliveryman.status.name = "Ag. aprovação";
              deliveryman.color = "#e5e619";
              break;
            case "Banido":
              deliveryman.color = "var(--color-danger)";
              break;
            case "Documentos pendentes":
              deliveryman.status.name = "Docs. pendentes";
              deliveryman.color = "#e5e619";
              break;
            case "Inativo":
              deliveryman.color = "gray";
              break;
            case "Aguardando confirmação do telefone":
              deliveryman.status.name = "Ag. telefone";
              deliveryman.color = "#e5e619";
              break;
            default:
              break;
          }
        });

        setCurrentPage(page);
        setTotalPages(deliverymansResponse.last_page);
        setDeliverymans(deliverymansResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDeliveryman = React.useCallback(
    debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleGetDeliverymans(8, 1, value);
      setQuery(value);
    }, 500),
    [handleGetDeliverymans],
  );

  const handleSetIsModalOpen = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, []);

  const handleViewDeliverymanDetails = useCallback(
    (deliveryman: DeliverymanFromGetDeliverymans) => {
      const filteredImages = deliveryman.files.map((file) => {
        return {
          src: `https://storage.googleapis.com/docs-loocal/public/deliverymans/${file.original_name}`,
          alt: file.doc_type.name,
        };
      });
      setDeliverymanImages(filteredImages);
      setSelectedDeliveryman(deliveryman);
      handleSetIsModalOpen();
    },
    [handleSetIsModalOpen],
  );

  useEffect(() => {
    handleGetDeliverymans();
  }, [handleGetDeliverymans]);

  return (
    <Container>
      <Sidebar />
      {isModalOpen && (
        <DeliverymanDetailsModal
          setIsOpen={handleSetIsModalOpen}
          isOpen={isModalOpen}
          deliverymanData={selectedDeliveryman}
          deliverymanImages={deliverymanImages}
          getDeliverymansData={handleGetDeliverymans}
        />
      )}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.DeliverymansContainer>
          <Styled.Title>
            <div>
              Gerenciar - Entregadores{" "}
              <FiRefreshCw
                size={20}
                onClick={() => handleGetDeliverymans(8, 1)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                style={{
                  marginRight: "30px",
                  background: "#6a64d9",
                  padding: "5px 14px",
                  cursor: "pointer",
                  transition: "0.3s",
                  color: "white",
                  fontSize: "12px",
                  textDecoration: "none",
                }}
                href="/gerenciar/novo-entregador"
              >
                + Novo Entregador
              </a>
              <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
                <BorderlessInput
                  type="text"
                  id="search"
                  name="search"
                  onChange={searchDeliveryman}
                  placeholder="Pesquisar"
                  autoComplete="off"
                />
              </Styled.SearchForm>
            </div>
          </Styled.Title>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.DeliverymansShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.DeliverymansShimmer>
            ) : (
              <Styled.Ul>
                {deliverymans.map((deliveryman) => {
                  return (
                    <Styled.Li
                      color={deliveryman.color || "lightblue"}
                      key={deliveryman.id}
                    >
                      <Styled.CommonField>
                        <span>Entregador:</span>
                        <strong>{deliveryman.user.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Apelido:</span>
                        <strong>
                          {deliveryman.nickname}{" "}
                          {deliveryman.preferential && "⭐"}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Cidade:</span>
                        <strong>{deliveryman.address.city.name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Registrado em:</span>
                        <strong>
                          {formatLocale(
                            deliveryman.created_at,
                            "dd/MM/yyyy 'às' HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.Separator />
                      <Styled.StatusField
                        color={deliveryman.color || "lightblue"}
                      >
                        {deliveryman.status.name}
                      </Styled.StatusField>
                      <Styled.ActionField>
                        <FiZoomIn
                          size={27}
                          onClick={() =>
                            handleViewDeliverymanDetails(deliveryman)
                          }
                        />
                        <Styled.Link
                          to={`/gerenciar/entregadores/${deliveryman.id}`}
                        >
                          <FiEdit size={23} />
                        </Styled.Link>
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetDeliverymans}
                totalPages={totalPages}
                currentPage={currentPage}
                searchQuery={query}
              />
            )}
          </Styled.ListContainer>
        </Styled.DeliverymansContainer>
      </ContainerMain>
    </Container>
  );
};

export default Deliverymans;
